import React, { Fragment } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styles from "../policiesContent.module.scss";

const linkOffset = 80;

const PrivacyContent = () => (
  <Fragment>
    <div className={styles.col1}>
      <AnchorLink className={styles.link} offset={linkOffset} href='#link1'>Tracking on Cybertent</AnchorLink>
      <AnchorLink className={styles.link} offset={linkOffset} href='#link2'>Google Analytics</AnchorLink>
    </div>
    <div className={styles.col2}>
      <p><em>Updated June 10, 2019</em></p>
      <p>Cybertent uses cookies to make interactions with our service easy and meaningful. We use cookies (and similar technologies, like HTML5 localStorage) to keep you logged in, remember your preferences, and provide information for future development of Cybertent.</p>
      <p>A cookie is a small piece of text that our web server stores on your computer or mobile device, which your browser sends to us when you return to our site. Cookies do not necessarily identify you if you are merely visiting Cybertent; however, a cookie may store a unique identifier for each logged in user. The cookies Cybertent sets are essential for the operation of the website, or are used for performance or functionality. By using our website, you agree that we can place these types of cookies on your computer or device. If you disable your browser or device’s ability to accept cookies, you will not be able to log in or use Cybertent's services.</p>
      <p>Cybertent sets the following cookies on our users for the following reasons:</p>
      <table>
        <thead>
          <tr>
            <th>Name of Cookie</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><code>_ga</code></td>
            <td>This cookie is used by Google Analytics.</td>
          </tr>
          <tr>
            <td><code>__utma</code></td>
            <td>This cookie is used by Google Analytics.</td>
          </tr>
          <tr>
            <td><code>__utmc</code></td>
            <td>This cookie is used by Google Analytics.</td>
          </tr>
          <tr>
            <td><code>__utmz</code></td>
            <td>This cookie is used by Google Analytics.</td>
          </tr>
        </tbody>
      </table>
      <p>Certain pages on our site may set other third party cookies. For example, we may embed content, such as videos, from another site that sets a cookie. While we try to minimize these third party cookies, we can’t always control what cookies this third party content sets.</p>
      <h2 id="link1">Tracking on Cybertent</h2>
      <p>&quot;<a href="https://www.eff.org/issues/do-not-track">Do Not Track</a>&quot; is a privacy preference you can set in your browser if you do not want online services — specifically ad networks — to collect and share certain kinds of information about your online activity from third party tracking services. Cybertent does not currently respond differently to an individual browser’s Do Not Track setting. If you would like to set your browser to signal that you would not like to be tracked, please check your browser’s documentation for how to enable that signal. There are also good applications that block online tracking, such as <a href="https://www.eff.org/privacybadger">Privacy Badger</a>.</p>
      <p>We do not track your online browsing activity on other online services over time and we do not host third-party advertising on Cybertent that might track your activity on our site. We do have agreements with certain vendors, such as analytics providers, who help us track visitors’ movements on certain pages on our site. Only our vendors, who are collecting data on our behalf, may collect data on our pages, and we have signed data protection agreements with every vendor who collects this data on our behalf. We use the data we receive from these vendors to better understand our visitors’ interests, to understand our website’s performance, and to improve our content.</p>
      <h3 id="link2"><em>Google Analytics</em></h3>
      <p>We use Google Analytics as a third party analytics service, but we don’t use it for advertising purposes. We use Google Analytics to collect information about how our website performs and how our users, in general, navigate through and use Cybertent. This helps us evaluate our users’ use of Cybertent; compile statistical reports on activity; and improve our content and website performance. Google provides further information about its own privacy practices and <a href="https://tools.google.com/dlpage/gaoptout">offers a browser add-on to opt out of Google Analytics tracking</a>.</p>
    </div>
  </Fragment>
)

export default PrivacyContent;
