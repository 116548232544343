import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PoliciesContent from '../components/PoliciesContent';
import HeroBanner from '../components/HeroBanner';

const Terms = () => (
  <Layout page="cookies">
    <SEO
      title="Cookie Policy"
      description="Cybertent Ltd. cookie policy"
      keywords={['cookies', 'web', 'mobile', 'developers']}
    />
    <HeroBanner title="Cookie policy" subtitle="Cybertent Ltd. cookie policy" />
    <PoliciesContent key="cookies" type="cookies" />
  </Layout>
)

export default Terms;
