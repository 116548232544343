import React from 'react';
import Cookies from './Cookies';
import styles from "./policiesContent.module.scss";

const PoliciesContent = () => {
  return (
    <div className={styles.wrapper}>
      <Cookies />
    </div>
  )
}

export default PoliciesContent;
